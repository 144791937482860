<template>
  <div class="md-profile">
    <div class="profile-picture__section">
      <div class="left-content">
        <h5 class="profile-heading">
          {{
            $t(
              "settings.my_details.professional_information.info_cards.profile_picture"
            )
          }}
        </h5>
        <span class="profile-sub__heading ">{{
          $t("settings.my_details.professional_information.info_cards.note_key")
        }}</span>
      </div>
      <div class="right-content">
        <div class="edit-img">
          <div v-if="!allProfile.profile_image_url">
            <span @click="openUploadModal" class="upload-picture">{{
              $t(
                "settings.my_details.professional_information.info_cards.upload_picture"
              )
            }}</span>
          </div>
          <div v-else>
            <span @click="onDelete" class="delete-picture mr-3">{{
              $t("general.delete")
            }}</span>
            <span @click="openUploadModal" class="change-picture__btn">{{
              $t(
                "settings.my_details.professional_information.info_cards.change_picture"
              )
            }}</span>
          </div>
        </div>

        <div class="md-profile__avatar ml-4">
          <b-img
            :src="
              allProfile.profile_image_url
                ? allProfile.profile_image_url
                : require('@/assets/images/menu/avatar.svg')
            "
            rounded="circle"
            width="72"
            height="72"
            alt="avatar"
          />
        </div>
      </div>
    </div>
    <div class="md-profile--edit sc-content__edit--wrapper">
      <!-- profile pic modal -->
      <b-modal
        id="profile-pic-modal"
        centered
        size="md"
        hide-footer
        :no-close-on-backdrop="true"
        header-class="modal-header--center modal-header--bb"
        modal-class="lxp-modal"
      >
        <template #modal-header="{close}">
          <div class="modal-header--div">
            <h5>
              {{ $t("settings.my_details.general.profile_photo.title") }}
            </h5>
            <img src="@/assets/images/close.svg" alt="close" @click="close()" />
          </div>
        </template>
        <div class="profile-profile__div">
          <b-row class="ep-div__sec">
            <div class="w-100">
              <img
                :src="
                  allProfile.profile_image_url
                    ? allProfile.profile_image_url
                    : require('@/assets/images/menu/avatar.svg')
                "
                alt="avatar"
                width="215"
                height="215"
                class="rounded-circle"
                id="profile-pic"
              />
            </div>
          </b-row>
          <div class="pp-footer__icons">
            <div class="pp-footer__icons--edit" @click="onEdit">
              <img src="@/assets/images/settings/icons/edit.svg" alt="edit" />
              <span class="d-block">{{ $t("general.edit") }}</span>
            </div>
            <div class=" pp-footer__icons--upload">
              <input
                type="file"
                id="profile"
                ref="profilePic"
                class="invisible"
                accept=".png,.jpg,.jpeg"
                @change="onUploadChange"
              />
              <label for="profile">
                <img
                  src="@/assets/images/settings/icons/camera.svg"
                  alt="camera"
                  width="21"
                  height="21"
                />
                <span class="d-block">{{ $t("general.upload") }}</span>
              </label>
            </div>
            <div
              class="pp-footer__icons--delete"
              v-b-modal.delete-profile-modal
            >
              <img src="@/assets/images/settings/icons/bin.svg" alt="bin" />
              <span class="d-block">{{ $t("general.delete") }}</span>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- edit pic modal -->
      <b-modal
        id="edit-pic-modal"
        centered
        size="md"
        :no-close-on-backdrop="true"
        header-class="modal-header--center modal-header--bb"
        modal-class="lxp-modal"
        @close="close()"
      >
        <template #modal-header="{close}">
          <div class="modal-header--div">
            <h5>
              {{
                showCropper
                  ? $t("my_profile.details.edit_picture")
                  : $t("settings.my_details.general.profile_photo.title")
              }}
            </h5>
            <img src="@/assets/images/close.svg" alt="close" @click="close()" />
          </div>
        </template>
        <div
          v-if="showErrorModal"
          id="error-msg-modal"
          class="error-modal__div"
        >
          <img
            src="@/assets/images/warning-yellow.svg"
            alt="warning-yellow"
            width="40"
            height="40"
            class="warning-image"
          />
          <div class="error-modal__message">
            {{ errorMessage
            }}<label for="profile-pic"
              ><span class="error-modal__message-orange">{{
                $t("settings.my_details.general.image_error.re_upload")
              }}</span></label
            >
          </div>
        </div>
        <div class="edit-profile__div" v-show="showCropper">
          <div class="w-100">
            <img
              :src="newImage"
              alt="image"
              width="280"
              id="new-profile-pic"
              v-show="false"
            />
          </div>
          <div class="edit-pic__actions">
            <div class="ep-action__zoom">
              <div class="ep-action__zoom--heading">
                <label>{{
                  $t("settings.my_details.general.profile_photo.zoom")
                }}</label>
                <label>{{ zoom }}</label>
              </div>
              <InputRange
                :min="0.1"
                :max="5"
                :step="0.1"
                id="zoom"
                :value.sync="zoom"
                @input="onZoom"
              ></InputRange>
            </div>
            <div class="ep-action__rotate">
              <div class="ep-action__zoom--heading">
                <label>{{
                  $t("settings.my_details.general.profile_photo.rotate")
                }}</label>
                <label>{{ rotation }}</label>
              </div>
              <InputRange
                :min="0"
                :max="360"
                :step="45"
                id="rotate"
                :value.sync="rotation"
                @input="onRotate"
              ></InputRange>
            </div>
            <img
              src="@/assets/images/settings/icons/reset.svg"
              alt="reset"
              @click="onReset"
              v-b-tooltip.click
              title="Reset"
              class="c-pointer"
            />
          </div>
        </div>
        <template #modal-footer>
          <LxpButton
            :variant="isPicUploadFailed ? 'warning' : 'primary'"
            block
            @click="onSave"
            v-show="showCropper"
          >
            <img
              src="@/assets/images/settings/icons/loader.svg"
              class="ct__loader"
              v-if="isPicUploading"
            />
            <span v-else>
              {{ isPicUploadFailed ? errorMessage : $t("general.save") }}</span
            ></LxpButton
          >
        </template>
      </b-modal>
      <!-- delete pic modal -->
      <b-modal
        id="delete-profile-modal"
        centered
        size="md"
        modal-class="lxp-modal"
      >
        <template #modal-header="{close}">
          <div class="modal-header__close">
            <img src="@/assets/images/close.svg" alt="close" @click="close()" />
          </div>
          <h5>{{ $t("my_profile.delete_picture.title") }}</h5>
        </template>
        <div>
          <p>{{ $t("my_profile.delete_picture.subtitle") }}</p>
        </div>
        <template #modal-footer="{hide}">
          <Button variant="outline-danger" @click="onDelete"
            >{{ $t("general.delete") }}
          </Button>
          <Button variant="primary" @click="hide()">{{
            $t("general.dismiss")
          }}</Button>
        </template>
      </b-modal>
    </div>
    <input
      type="file"
      id="profile-pic"
      ref="profilePic"
      class="invisible"
      accept=".png,.jpg,.jpeg"
      @change="onUploadChange"
    />
  </div>
</template>
<script>
import { LxpButton } from "didactica";

import SettingsMixin from "../mixin";

import Cropper from "cropperjs";

export default {
  mixins: [SettingsMixin],
  components: { LxpButton },
  data() {
    return {
      showGenderText: false,
      onUpload: false,
      newImage: null,
      croppedImage: null,
      fileName: "",
      zoom: 0.5,
      rotation: 1,
      isPicUploading: false,
      isPicUploadFailed: false,
      given_name: "",
      errorMessage: "",
      showCropper: true,
      showErrorModal: false
    };
  },
  methods: {
    openUploadModal() {
      if (this.allProfile.profile_image_url) {
        this.$bvModal.show("profile-pic-modal");
      } else {
        document.getElementById("profile-pic").click();
      }
    },
    initCropper(image) {
      this.croppedImage = new Cropper(image, {
        minContainerHeight: 270,
        background: false,
        guides: false,
        cropBoxResizable: false
      });
    },
    openEditModal() {
      this.$bvModal.show("edit-pic-modal");
      setTimeout(() => {
        if (this.croppedImage) {
          this.croppedImage.destroy();
        }
        this.initCropper(document.getElementById("new-profile-pic"));
      }, 10);
    },
    onDelete() {
      this.$store.dispatch("deleteProfilePicture").then(() => {
        this.onUpload = false;
        this.$bvModal.hide("delete-profile-modal");
        this.$bvModal.hide("profile-pic-modal");
      });
    },
    onEdit() {
      this.newImage = this.allProfile.profile_image_url;
      this.isPicUploading = "";
      this.isPicUploadFailed = false;
      this.showCropper = true;
      this.showErrorModal = false;
      this.openEditModal();
    },
    onZoom(e) {
      this.zoom = e;
      this.croppedImage.zoomTo(this.zoom);
    },
    onRotate(e) {
      this.rotation = e;
      this.croppedImage.rotateTo(this.rotation);
    },
    onReset() {
      this.croppedImage.reset();
      this.zoom = 0.5;
      this.rotation = 1;
    },
    close() {
      // Reset the file value to empty, as File UploadChange event is not being fired
      // in Chrome browser if same image is selected again.
      if (this.$refs && this.$refs.profilePic) {
        this.$refs.profilePic.value = "";
      }
    },
    onUploadChange(e) {
      let file = e.target.files[0];
      if (file) {
        this.fileName = file.name;
        this.errorMessage = "";
        this.isPicUploadFailed = "";
        this.showCropper = true;
        this.showErrorModal = false;

        const validationStatus = this.fileuploadValidation(file);

        if (validationStatus.isValid) {
          this.newImage = URL.createObjectURL(file);
          this.openEditModal();
        } else {
          this.onInvalidProfilePicture(false, true);
          this.openEditModal();
          this.errorMessage = validationStatus.errorMessage;
        }
      }
    },
    onInvalidProfilePicture(displayCropper, displayErrorModal) {
      this.isPicUploading = false;
      this.isPicUploadFailed = true;
      this.showCropper = displayCropper;
      this.showErrorModal = displayErrorModal;
    },
    onSave() {
      this.isPicUploading = true;
      this.errorMessage = "";
      this.croppedImage.getCroppedCanvas().toBlob(blob => {
        let file = new File([blob], this.fileName, {
            type: blob.type
          }),
          formData = new FormData();
        formData.append("profile_image", file);

        this.$store
          .dispatch("updateProfilePicture", formData)
          .then(() => {
            this.isPicUploading = false;
            this.showErrorModal = false;
            this.$bvModal.hide("edit-pic-modal");
          })
          .catch(error => {
            // Hide error modal for server errors
            this.onInvalidProfilePicture(true, false);
            if (error.response) {
              this.errorMessage = this.$t(
                "settings.my_details.general.image_error.server"
              );
            } else if (error.request) {
              this.errorMessage = this.$t(
                "settings.my_details.general.image_error.network"
              );
            } else {
              this.errorMessage = this.$t(
                "settings.my_details.general.image_error.file_upload"
              );
            }
          });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-profile {
  background: $brand-neutral-0;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;

  .profile-picture__section {
    display: flex;
    justify-content: space-between;
    .left-content {
      .profile-heading {
        @include settings-header;
        margin-bottom: 8px;
      }
      .profile-sub__heading {
        @include body-medium;
        color: $brand-neutral-700;
      }
    }
    .right-content {
      display: flex;
      align-items: center;
      .upload-picture,
      .change-picture__btn,
      .delete-picture {
        @include label-small;
        letter-spacing: 0.2px;
        color: $brand-primary-400;
        text-transform: capitalize;
        cursor: pointer;
        font-size: 14px;
      }
      .change-picture__btn {
        background: $brand-primary-100;
        padding: 8px 10px;
        border-radius: 100px;
      }
      .delete-picture {
        color: $brand-neutral-700;
      }
    }
  }
  .md-profile__avatar {
    display: flex;
    align-items: center;
    .rounded-circle {
      object-fit: cover;
    }
  }
}
#profile-pic-modal {
  .profile-profile__div {
    @include center;
    .ep-div__sec {
      margin: 16px 0 8px;
      .rounded-circle {
        object-fit: cover;
      }
    }
  }
  .pp-footer__icons {
    @include flex-horizontal-center;
    margin: 10px 0 24px;
    > div {
      text-align: center;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      span {
        @include label-large;
        margin-top: 4px;
      }
    }
    .pp-footer__icons--upload {
      input {
        width: 0;
        height: 0;
      }
      label {
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
#edit-pic-modal {
  .modal-dialog {
    max-width: 600px;
  }
  .edit-profile__div {
    padding: 24px;
    .w-100 {
      min-height: 270px;
    }
    .edit-pic__actions {
      @include horizontal-space-between;
      margin-top: 10px;
      .ep-action__zoom,
      .ep-action__rotate {
        width: 42%;
        text-align: left;
        display: flex;
        flex-direction: column;
        .ep-action__zoom--heading {
          @include horizontal-space-between;
          margin-bottom: 6px;
          label {
            @include label-medium;
            margin-bottom: 0;
            font-weight: 500;
          }
        }
        input[type="range"] {
          margin: 0;
        }
      }
    }
  }
  .ct__loader {
    animation: spin 1000ms infinite linear;
    @include spin-animation-mixin(0deg, 360deg);
  }
}
#error-msg-modal {
  .error-modal__message {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
  .error-modal__message-orange {
    font-weight: normal;
    color: $brand-warning;
    font-family: $font-family;
    cursor: pointer;
    padding: 0px 0px 0px 4px;
  }
  .warning-image {
    margin: 30px 0px 10px 0px;
  }
  .invisible__error-modal {
    display: none;
  }
}
</style>
